.loading {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: #fff;
  z-index: 10;
}

.remove {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 10;
  top: 0;
  background: rgba(0, 0, 0, 0.61);
}

.full-screen {
  position: absolute;
  inset: 0;

  background-color: #161616a9;
  z-index: 9999;
  pointer-events: none;
}

.full-screen > span {
  color: #ffffff;
}
