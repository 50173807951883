.wrapper {
  width: 100%;
  color: #000;
}
.titleWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.625rem;
  margin: -1.3rem 0 0.7rem 0;
}

.title {
  /*color: rgba(103, 109, 125, 1);*/
  font-size: 0.875rem;
  line-height: 0.75rem;
  letter-spacing: 0.03rem;
  /*text-transform: uppercase;*/
}

.input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 2.375rem;
  border-radius: 0.375rem;
  border: 0.0625rem solid rgba(255, 255, 255, 0.3);
  font-size: 0.875rem;
  padding: 0.875rem 1rem;
  line-height: 1.25rem;
  cursor: pointer;
}
.inputActive {
}

.gray {
  border: none;
  background: #1b201f;
}

.list {
  position: absolute;
  top: 1.875rem;
  left: 0.125rem;
  border: 0.0625rem solid rgba(0, 0, 0, 0.2);
  padding: 0.875rem 1rem;
  width: 99%;
  max-height: 150px;
  overflow-y: auto;
  background-color: white;
  z-index: 100;
  list-style: none;
}

.item {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 2.5rem;
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1.875rem;
  color: #000000;
  text-align: left;
}
.item:before {
  content: '';
  display: block;
  background-image: url('../../../../assets/icons/radio-button.svg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 1.5rem;
  width: 1.5rem;
  position: absolute;
  left: 0.625rem;
}

.itemSelected {
  cursor: pointer;
  text-align: left;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.875rem;
  color: #000000;
  padding-left: 2.5rem;
}

.itemSelected:before {
  content: '';
  display: block;
  background-image: url('../../../../assets/icons/radio-button-active.svg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 1.5rem;
  width: 1.5rem;
  position: absolute;
  left: 0.625rem;
}

.item:hover {
}
.item:hover:before {
  background-image: url('../../../../assets/icons/radio-button-active.svg');
}
