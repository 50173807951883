.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #0000009c;
}

.backdrop.right > * {
  position: absolute;
  right: 0;
}

.backdrop.left > * {
  position: absolute;
  left: 0;
}

.backdrop.bottom > * {
  width: 100%;
  height: 100%;
  bottom: 0;
  display: flex;
  align-items: flex-end;
}
