.countdown-container {
  position: relative;
}

.circle {
  width: 70px;
  height: 70px;
}

.circle-bg {
  stroke: #ccc;
  stroke-width: 4;
}

.circle-fill {
  stroke: #00cc66; /* Green color */
  stroke-width: 4;
  stroke-dasharray: 157;
  transition: stroke-dashoffset 1s linear;
}

.time {
  position: absolute;
  top: 19px;
  left: 9px;
  font-size: 20px;
  margin-left: 10px;
}
