.container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(0, 0, 0, 0.3);
  z-index: 4;

  position: absolute;
  inset: 0;
  overflow: hidden;
  backdrop-filter: blur(0.25rem);
}

.content {
  width: 60.5rem;

  max-height: 50rem;

  background: white;

  position: relative;

  padding: 5rem 3.75rem 5rem 3.75rem;
  border-radius: 0.375rem;
  overflow-y: hidden;
}

.children {
  width: 100%;

  transition: height;

  max-height: 37.5rem;
  overflow-y: auto;
}

.children::-webkit-scrollbar {
  width: 0;
}

.close {
  position: absolute;
  top: 2.5rem;
  right: 3.75rem;
  cursor: pointer;
}

.back {
  position: absolute;
  top: 2.5rem;
  left: 25rem;
  font-size: 2rem;
  /*cursor: pointer;*/
}
